<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-card-text>
          <b-row>
            <b-col
              xs="12"
              md="6"
            >
              <b-form-group
                label="Banka"
                label-for="id_pos_configs"
              >
                <v-select
                  id="id_pos_configs"
                  v-model="filterWhere.id_pos_configs"
                  label="title"
                  :options="banks"
                  placeholder="Seçiniz"
                  :reduce="bank => bank.id"
                />
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              md="6"
            >
              <b-form-group
                label="Durum"
                label-for="status"
              >
                <v-select
                  id="status"
                  v-model="filterWhere.result"
                  label="title"
                  :options="statusData"
                  placeholder="Seçiniz"
                  :reduce="status => status.id"
                />
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              md="6"
            >
              <b-form-group
                label="Başlangıç Tarihi"
                label-for="sdate"
              >
                <b-form-datepicker
                  id="sdate"
                  v-model="filterWhere.sdate"
                  v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                  locale="tr"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col
              xs="12"
              md="6"
            >
              <b-form-group
                label="Bitiş Tarihi"
                label-for="edate"
              >
                <b-form-datepicker
                  id="edate"
                  v-model="filterWhere.edate"
                  v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                  locale="tr"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mb-2 text-center"
            >
              <b-button
                variant="primary"
                @click="getDataList"
              >
                <FeatherIcon icon="FilterIcon" />
                Filtrele
              </b-button>
              <b-button
                variant="success"
                class="ml-1"
                :href="downloadURL"
                target="_blank"
              >
                <FeatherIcon icon="FileIcon" />
                Excel Aktar
              </b-button>
            </b-col>
          </b-row>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            fixed
          >
            <template #cell(customer)="data">
              {{ data.item.customer }}
            </template>
            <template #cell(amount)="data">
              <div class="text-right">
                {{ data.item.amount | toCurrency }} TL
              </div>
            </template>
            <template #cell(result)="data">
              <div class="text-center">
                <b-badge
                  v-if="data.item.result === '1'"
                  variant="success"
                >
                  Başarılı
                </b-badge>
                <b-badge
                  v-else-if="data.item.result === '0'"
                  variant="danger"
                >
                  Başarısız
                </b-badge>
                <b-badge
                  v-else
                  variant="warning"
                >
                  Eksik İşlem
                </b-badge>
              </div>
            </template>
            <template #cell(created)="data">
              <div class="text-right">
                <div class="text-primary font-small-2">
                  {{ moment(data.item.created).format('DD.MM.YYYY HH:mm') }}
                </div>
                <div class="text-primary font-weight-bold">
                  {{ data.item.username }}
                </div>
              </div>
            </template>
            <template #cell(control)="data">
              <b-dropdown
                text="Detay"
                variant="outline-primary"
                size="sm"
                block
              >
                <b-dropdown-item :to="'/payment-histroy/view/' + data.item.id">
                  İşlem Detayı
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="data.item.result !== '1'"
                  :href="`${baseurl}/exports/pdf/payments?token=${downloadToken}&id=${data.item.id}`"
                  target="_blank"
                >
                  Makbuz Yazdır
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #custom-foot>
              <b-tr>
                <b-th colspan="2">
                  Toplam
                </b-th>
                <b-th class="text-center">
                  {{ dataTotal | toCurrency }} TL
                </b-th>
                <b-th
                  class="text-center"
                  colspan="3"
                />
              </b-tr>
            </template>
          </b-table>
        </b-card-text>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BBadge, BDropdown, BDropdownItem, BFormGroup, BFormDatepicker, BButton, BTr, BTh,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'UserTypes',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BTr,
    BTh,
    vSelect,
  },
  data() {
    return {
      baseurl: this.$store.state.app.baseURL,
      downloadToken: localStorage.getItem('downloadToken'),
      currentPage: 1,
      perPage: 10,
      search: '',
      filterWhere: {
        sdate: null,
        edate: null,
        result: '',
        id_pos_configs: null,
      },
      statusData: [
        { id: 1, title: 'Başarılı' },
        { id: 0, title: 'Başarısız' },
        { id: null, title: 'Eksik İşlem' },
      ],
      fields: [
        {
          key: 'customer',
          label: 'MÜŞTERİ',
        },
        {
          key: 'bank',
          label: 'Banka',
          thStyle: { width: '200px' },
        },
        {
          key: 'amount',
          label: 'İŞLEM TUTARI',
          thStyle: { textAlign: 'right', width: '150px' },
        },
        {
          key: 'result',
          label: 'DURUM',
          thStyle: { textAlign: 'center', width: '140px' },
        },
        {
          key: 'created',
          label: 'İŞLEM TARİHİ',
          thStyle: { textAlign: 'right', width: '200px' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
        },
      ],
      dataQuery: {
        select: [
          'payments.id AS id',
          'banks.title AS bank',
          'customers.company AS customer',
          'payments.amount AS amount',
          'payments.result AS result',
          'users.name AS username',
          'payments.created AS created',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['paymentsHistory/getPayments_history']
      return data
    },
    dataCount() {
      return this.$store.getters['paymentsHistory/getPayments_historyCount']
    },
    dataTotal() {
      return this.$store.getters['paymentsHistory/getPayments_historyTotal']
    },
    banks() {
      return this.$store.getters['posConfigs/getPos_configs']
    },
    downloadURL() {
      const urlParams = [
        `&result=${this.filterWhere.result}`,
        `&sdate=${this.filterWhere.sdate}`,
        `&edate=${this.filterWhere.edate}`,
        `&id_pos_configs=${this.filterWhere.id_pos_configs}`,
      ].join('&')
      const url = `${this.$store.state.app.baseURL}/exports/excel/payments?token=${localStorage.getItem('downloadToken')}${urlParams}`
      return url
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
    this.getBanks()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      const query = this.dataQuery
      // eslint-disable-next-line
      let where = null
      // eslint-disable-next-line
      let result = {}
      // eslint-disable-next-line
      let id_pos_configs = {}
      // eslint-disable-next-line
      let sdate = {}
      // eslint-disable-next-line
      let edate = {}
      if (this.filterWhere.result !== '') {
        result = { 'payments.result': this.filterWhere.result }
      }
      if (this.filterWhere.id_pos_configs !== null) {
        // eslint-disable-next-line camelcase
        id_pos_configs = { 'payments.id_pos_configs': this.filterWhere.id_pos_configs }
      }
      if (this.filterWhere.sdate !== null) {
        sdate = { 'payments.created >=': this.filterWhere.sdate }
      }
      if (this.filterWhere.edate !== null) {
        edate = { 'payments.created <=': this.filterWhere.edate }
      }
      where = Object.assign(result, sdate, edate, id_pos_configs)
      query.where = where
      this.$store.dispatch('paymentsHistory/payments_historyList', query)
    },
    getBanks() {
      this.$store.dispatch('posConfigs/pos_configsList', {
        select: [
          'pos_configs.id AS id',
          'banks.title AS title',
        ],
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
